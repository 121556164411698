import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAcopiosByClienteId } from "../../actions/acopios";
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import { Container } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Acopio from "./acopio";

const Acopios = (props) => {
  const local = JSON.parse(window.localStorage.getItem("LocalSession"));
  const [acopios, setAcopios] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAcopiosByClienteId(local.clienteId.trim()));
  }, []);

  useEffect(() => {
    if (props.acopios.data) {
      setAcopios(props.acopios?.data.$values);
    }
  }, [props.acopios]);

  // useEffect(() => {
  //   if(props.estados.data){
  //     setEstados(props.estados?.data.$values);
  //   }
  // }, [props.estados])

  return (
    <div
      style={{
        backgroundColor: colores.grisClaro,
        overflow: "hidden",
        clear: "both",
      }}
    >
      <div>
        <Dolar></Dolar>
        {/* HEADER */}
        <div
          className="container-fluid"
          style={{
            width: "100%",
            overflow: "hidden",
            clear: "both",
            marginBottom: "1.85rem",
          }}
        >
          <div className="d-flex justify-content-center">
            <hr style={{ width: "90%", color: "black", marginTop: "0" }}></hr>
          </div>
          <Container
            maxWidth="w-100"
            style={{ paddingRight: "60px", paddingLeft: "60px" }}
          >
            <div
              className="d-flex justify-content-between flex-wrap align-items-center "
              style={{ marginBottom: "0.600rem", paddingRight: "3.5rem" }}
            >
              <h1 className="fw-bolder" style={{ fontSize: "bolder" }}>
                MIS ACOPIOS
              </h1>
            </div>
          </Container>
        </div>
      </div>
      <div
        className="container-fluid mx-0 px-0 w-100"
        style={{ margin: "2rem" }}
      >
        <div
          className="container-fluid"
          style={{
            backgroundColor: "white",
            width: "100%",
            overflow: "hidden",
            clear: "both",
            marginBottom: "1.85rem",
            paddingLeft: "76px",
            paddingRight: "76px",
          }}
        >
          <div style={{ margin: "2vw" }}>
            {props?.loading ? (
              <div
                style={{ marginTop: "10vh", marginBottom: "4vh" }}
                className="container-fluid w-50  d-flex justify-content-center"
              >
                <h4>Cargando...</h4>
              </div>
            ) : (
              ""
            )}
            {!props?.loading && acopios.length <= 0 ? (
              <div
                style={{ marginTop: "10vh", marginBottom: "4vh" }}
                className="container-fluid w-50  d-flex justify-content-center"
              >
                <h4>No tenés acopios</h4>
              </div>
            ) : (
              <div className="d-flex flex-wrap">
                {acopios.map((acopio) => (
                  <Acopio key={acopio.id} data={acopio} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    acopios: state.acopios.acopios,
    estados: state.acopios.estados,
    loading: state.acopios.loading,
  }),
  (dispatch) => ({
    getAcopiosByClienteId: (clienteId) =>
      dispatch(getAcopiosByClienteId(clienteId)),
  })
)(Acopios);
