import React from "react";
import Dolar from "../../routes/dolar";
import Title from "../commons/header_title";
import colores from "../../shared/constants/styles";
import EstadoPedidos from "./estadopedido/estado_pedidos";
import Factura from "./factura/factura";
import Composicion from "./composicion/composicion";
import Saldo from "./saldo/saldo";
import { connect } from "react-redux";
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import './index.css';
import { Margin } from "@mui/icons-material";

const CuentaCorriente = () => {
    const [value, setValue] = React.useState('facturas');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div
            style={{
                backgroundColor: colores.grisClaro,
                overflow: "hidden",
                clear: "both",
            }}
            >
            <div>
                {/* <Dolar></Dolar> */}
                <Title title={"CUENTA CORRIENTE"}></Title>
            </div>
            <div
                className="container-fluid mx-0 px-0 w-100"
                style={{ margin: "2rem" }}
            >
                <div
                className="container-fluid"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    overflow: "hidden",
                    clear: "both",
                    marginBottom: "1.85rem",
                    paddingLeft: "76px",
                    paddingRight: "76px",
                }}
                >
                    <Box sx={{ width: '100%', marginTop: '4vh'}}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList  variant="scrollable"
                                    scrollButtons="auto" onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Facturas" style={{fontSize: '20px'}} value="facturas" />
                                    <Tab label="Estado de Pedidos" style={{fontSize: '20px'}} value="estadopedido" />
                                    <Tab label="Saldo Cuenta Corriente" style={{fontSize: '20px'}} value="saldo" />
                                    <Tab label="Composición del Saldo Cta. Cte." style={{fontSize: '20px'}} value="composicion" />
                                </TabList>
                            </Box>
                            <TabPanel value="facturas"><Factura> </Factura></TabPanel>
                            <TabPanel value="estadopedido"><EstadoPedidos> </EstadoPedidos></TabPanel>
                            <TabPanel value="saldo"><Saldo> </Saldo></TabPanel>
                            <TabPanel value="composicion"><Composicion> </Composicion></TabPanel>
                        </TabContext>
                    </Box>                    
                </div>
            </div>
        </div>
    );
};
export default connect()(CuentaCorriente);
