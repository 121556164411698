import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/tracking/`

export default class tracking {
    static async getTracking(pedidoId) {
        const respuesta = await axios.get(`${ENDPOINT}getTracking/${pedidoId}`)
        .then(response => {
            return response
        })
        return respuesta.data
    }
}

