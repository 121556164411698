import { useEffect, useState } from "react";
import { Grid } from '@material-ui/core';
import colores from '../../shared/constants/styles';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from "@mui/material/TextField";
import { connect, useDispatch } from "react-redux";
import { requestEditAddress } from "../../actions/cliente";
import IUpdateAddressRequest from "./interface/IUpdateAddressRequest";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface ITiendaProps {
    data: any;
    success: any;
    loading: any;
}

const Tienda = (props: ITiendaProps) => {
    const dispatch = useDispatch();
    const [address, setAddress] = useState<string>(props.data?.calle);
    const [number, setNumber] = useState<string>(props.data?.calleNro);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');

    const onHandlerEdit = () => {
        setIsEdit(true);
    };
    const onHandlerSave = () => {
        setIsEdit(false);
        const updateAddressRequest: IUpdateAddressRequest ={
            address: address,
            addressNro: number,
            clienteId: localStorage.clienteId.trim(),
            tienda: props.data.tiendaNro
        }
        dispatch(requestEditAddress(updateAddressRequest));
    };
    const onHandlerCancel = () => {
        setIsEdit(false);
    };

    const handleAddressToChange = (event: any) => {
        setAddress(event.target.value);
    };
    const handleNumberToChange = (event: any) => {
        setNumber(event.target.value);
    };

    useEffect(() => {
        console.log(props.success)
        // if (props.estadopedidos.data) {
        //     setAcopios(props.acopios?.data.$values);
        // }
    }, [props]);

    return (
        <Grid className="bordesRedondos tienda" item xs={12} sm={6} md={4} lg={4} style={{marginBottom: "2.5rem"}}>
            <div className="bordesRedondosTop white-color p-4" style={{backgroundColor: colores.azulOscuro}}>
                <Grid container>
                    <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                        <StoreMallDirectoryOutlinedIcon style={{fill: "white", width: "4vh", height: "4vh"}}/>  
                        <h5 style={{margin: "6px"}}>{props.data?.nombreFantasia && props.data.nombreFantasia.charAt(0).toUpperCase() + props.data.nombreFantasia.slice(1).toLowerCase()}</h5>
                    </Grid>
                    {/* <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                        {!isEdit &&<EditIcon style={{fill: "white", width: "2vh", height: "2vh"}} onClick={onHandlerEdit}/>  }
                        {isEdit &&<SaveIcon style={{fill: "white", width: "3vh", height: "3vh"}} onClick={onHandlerSave}/>  }
                        {isEdit &&<CancelIcon style={{fill: "white", width: "2vh", height: "2vh"}} onClick={onHandlerCancel}/>  }
                    </Grid> */}
                </Grid>
            </div>
            <Grid item xs={12} style={{backgroundColor: colores.grisClaro}} className="bordesRedondosBottom p-4">
                <Grid item className="atributoTitle">N°</Grid>
                <Grid item className="atributoDato">{props.data?.tiendaNro}</Grid>
                <Grid item className="atributoTitle">Teléfono</Grid>
                <Grid item className="atributoDato">{props.data?.telefono}</Grid>
                <Grid item className="atributoTitle">Dirección</Grid>
                {!isEdit && <Grid item className="atributoDato">{address ? address: props.data?.calle}</Grid>}
                {isEdit && <TextField
                        id="address"
                        defaultValue={address ? address: props.data?.calle}
                        onChange={handleAddressToChange}
                        name="address"
                        variant="outlined"
                        size="small"
                    />}
                <Grid item className="atributoTitle">Número</Grid>
                {!isEdit && <Grid item className="atributoDato">{number? number: props.data?.calleNro }</Grid>}
                {isEdit && <TextField
                        id="number"
                        defaultValue={number? number: props.data?.calleNro}
                        onChange={handleNumberToChange}
                        name="number"
                        variant="outlined"
                        size="small"
                    />}

                { props.loading && 
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>}
            </Grid> 
            
        </Grid>
    )
}
export default connect(
    (state: any) => ({
        success: state.clientes.success,
        loading: state.clientes.loading,
    }),
    (dispatch) => ({
        requestEditAddress: (updateAddressRequest: IUpdateAddressRequest) => dispatch(requestEditAddress(updateAddressRequest)),
    })
  )(Tienda);