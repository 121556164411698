import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Dolar from '../../routes/dolar';
import colores from '../../shared/constants/styles';
import { isAllowed } from '../../shared/utils/permissions';
import BannerReclamo from '../../assets/images/Banner-Reclamos.png'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { postReclamoByClientId } from '../../actions/reclamos';

const NuevoReclamo = (props) => {
    const localsession = JSON.parse(window.localStorage.getItem("LocalSession"));
    const { idPresupuesto } = useParams();
    const [tipoReclamo, setTipoReclamo] = useState('');
    const [inicio, setInicio] = useState(true);
    const dispatch = useDispatch()  
    const history = useHistory();
    const [formData, setFormData] = useState({
        titulo: '',
        tipo: null,
        asunto: '',
        descripcion: '',
        nroPedido: decodeURIComponent(idPresupuesto),
        cuit: localsession.documentoNro,
        file: []
    });

    useEffect(() => {
        setInicio(true);
    }, []);
    const handleChangeTitulo= (e) => {
        setFormData({
            ...formData,
            titulo: e.target.value,
        });
    }
    const handleChangeTipo = (event: SelectChangeEvent) => {
        setFormData({
            ...formData,
            tipo : event.target.value
        })
        setTipoReclamo(event.target.value);
    };
    const handleChangeAsunto= (e) => {
        setFormData({
            ...formData,
            asunto: e.target.value,
        });
    }
    const handleChangeDescripcion= (e) => {
        setFormData({
            ...formData,
            descripcion: e.target.value,
        });
    }

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files
        });
    };
    
    const handleClick = () => {
        setInicio(false);
        dispatch(postReclamoByClientId(formData));
    }

    useEffect(() => {
        if(!inicio && props?.success){
            history.push(`/cuenta-corriente`);
        }
    }, [props])

    return (
        <div style={{backgroundColor: colores.grisClaro}}>
            <div style={{width:"100%"}}>
                { props.loading 
                    ? <div style={{ height : "120px" }}></div>
                    : <img src={BannerReclamo} style={{width:"100%"}} alt="grupo"/>}
                <h1 style={{fontWeight: "bold", display: "flex", marginTop: "-9vh", marginLeft: "1vw", fontSize: "45px", color: "white"}}>
                    CARGA DE RECLAMOS</h1>
            </div>
            <Dolar></Dolar>
            
            <div>
                <div className="container-fluid d--flex flex-row mb-3 flex-wrap" style={{backgroundColor:"white", padding:"1rem"}}>
                    <div className="container-fluid" style={{width: "100%", height: "100%", overflow:"hidden", clear:"both"}}>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            <h2 style={{fontWeight: "bold", display: "flex", fontSize: "35px"}}>Completá el siguiente formulario</h2>
                        </div>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            <div  className="row" style={{width: "100%"}}>
                                <div className="col-6">
                                    <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Título*</div>
                                    <FormControl variant="standard" style={{width: "100%"}}>
                                        <InputLabel htmlFor="titulo-reclamo">Título del reclamo</InputLabel>
                                        <Input
                                            id="titulo-reclamo"
                                            type="text"
                                            onChange={handleChangeTitulo}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle visibility">
                                                        <DriveFileRenameOutlineIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>                    
                                </div>                   
                            </div>                   
                        </div>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            <div  className="row" style={{width: "100%"}}>
                                <div className="col-6">
                                    <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Tipo de reclamo*</div>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: "100%"}}>
                                        <InputLabel id="tipo-reclamo-label">Seleccioná</InputLabel>
                                        <Select
                                            labelId="tipo-reclamo-label"
                                            id="tipo-reclamo"
                                            value={tipoReclamo}
                                            onChange={handleChangeTipo}
                                            label="TipoReclamo"
                                        >
                                            <MenuItem value={1}>Producto- Calidad</MenuItem>
                                            <MenuItem value={2}>Producto- Logística</MenuItem>
                                            <MenuItem value={3}>Servicio- Logística</MenuItem>
                                            <MenuItem value={4}>Servicio- Comercial</MenuItem>
                                            <MenuItem value={5}>Otros</MenuItem>
                                        </Select>
                                    </FormControl>  
                                </div>  

                                {/* <div className="col-6">
                                    <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Asunto del reclamo*</div>
                                    <Input
                                        style={{ marginTop: "24px", width: "100%"}}
                                        id="asunto-reclamo"
                                        onChange={handleChangeAsunto}
                                        type="text"
                                    />
                                </div>              */}
                            </div>  
                        </div>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            <div  className="row" style={{width: "100%"}}>
                                <div className="col-12">
                                    <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Descripción del reclamo*</div>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        >
                                        <TextField
                                            style={{width: "100%"}}
                                            id="descripcion-reclamo"
                                            onChange={handleChangeDescripcion}
                                            multiline
                                            rows={2}
                                            variant="standard"
                                            />
                                    </Box>  
                                </div>             
                            </div>                    
                        </div>
                        <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                            <div  className="row" style={{width: "100%"}}>
                                <div className="col-6">
                                    <div style={{display: "flex", fontSize: "20px", marginTop: "2vw"}}>Necesito adjuntar imágenes o archivos</div>
                                    {/* <Button
                                        className="my-3"
                                        style={{ backgroundColor: colores.grisClaro, color: colores.grisOscuro, textTransform: 'capitalize', height: '35px', width: '120px'  }}
                                        variant="contained"
                                        htmlFor="file-input"
                                    >
                                        <label htmlFor="file-input" style={{ width: '100%', height: '100%', cursor:'pointer', fontSize: '15px', marginTop: '6px' }}>
                                            Examinar...</label>
                                    </Button> */}
                                    <input
                                            id="file-input"
                                            type="file"
                                            multiple
                                            style={{ border: '1px solid #ccc', display: 'inline-block', padding: '6px 12px', cursor: 'pointer', borderRadius: '5px', marginTop: '16px' }}
                                            // style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                </div>  

                                <div className="col-6" style={{ marginTop:'7.4vh' }}>
                                    <Button
                                        className="my-3"
                                        style={{ backgroundColor: colores.rojo, color: '#fff', float: 'right' }}
                                        variant="contained"
                                        onClick={() => handleClick()}
                                        type="button"
                                    >
                                        ENVIAR
                                    </Button>
                                </div>             
                            </div>  
                        </div>
                        { props?.error 
                            ? <div className="container d-flex flex-row flex-wrap" style={{ marginBlock:"2rem"}}>
                                <div  className="row" style={{width: "100%"}}>
                                    <div className="col-12">
                                        <p style={{ color: 'red' }}>Error al crear el reclamo. Por favor, intente más tarde.</p>
                                    </div>
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect
(
  state => ({
    reclamos: state.reclamos.reclamos,
    success: state.reclamos.success,
    loading: state.reclamos.loading,
    error: state.reclamos.error,
  }),
  dispatch => ({
    postReclamoByClientId: (reclamo) => dispatch(postReclamoByClientId(reclamo))
  })
)(NuevoReclamo);