import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { requestProducto } from "../../actions/producto";
import {
  requestPresupuestoAdd,
  addProductFalse,
} from "../../actions/presupuesto";
import { useFormik } from "formik";
import * as Yup from "yup";
import CardProduct from "./cardProduct";
import ProductoSinImagen from "../../assets/images/ProductoSinImagen.png";
import Dolar from "../../routes/dolar";
import rutas from "../../routes/constants/routes";
import { Link } from "react-router-dom";
import colores from "../../shared/constants/styles";
import { Button } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { isAllowed } from "../../shared/utils/permissions";
import MyUserService from "../../services/myUser";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  requestDownloadFile,
  closeDownloadFile,
  requestConsultarDisponibilidad,
} from "../../actions/producto";
import "./producto.css";
import CalendarLogo from "../../assets/icons/calendar.svg";

const Producto = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  /* Permite leer el parámetro que envía la URL */
  const { id } = useParams();
  var clienteSeleccionado = JSON.parse(
    window.localStorage.getItem("selectedClient")
  );

  const myUser = MyUserService.getMyUser();
  const grupoId = MyUserService.getMyGroup();
  const paramsList = MyUserService.getMyUser().paramsList;
  const producto = props.producto;
  const showLargo = producto?.unidadMedida?.metroLineal ? true : false;
  const showAncho = producto?.unidadMedida?.metroCuadrado ? true : false;
  const [selectedImage, setselectedImage] = React.useState(null);
  var cantMin =producto?.cantMin ? producto?.cantMin: 1;
  var largoMin = producto?.largoMin ?? 1;
  var largoMax = producto?.largoMax ?? Infinity;
  // var cantProdMin= producto?.cantMin;
  var productsSuggestion = [];
  producto?.productSuggestions?.$values?.filter((e, i) => {
    if (i < 5) {
      productsSuggestion.push(e);
    }
  });
  if (producto?.unidadMedida !== null) {
    if (showAncho) {
      cantMin =
        paramsList?.$values?.find(
          (p) => p.nombre.trim() === "ProductoCantidadMinimaMC"
        ).numero ?? cantMin;
      }
  }

  useEffect(() => {
    if (isAllowed(18)) {
      dispatch(requestProducto(id, myUser.clienteId));
      dispatch(addProductFalse());
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.enviado) {
      setOpen(true);
    }
  }, [props.enviado]);

  useEffect(() => {
    if (props.file !== "" && props.successDownload === true) {
      dispatch(closeDownloadFile());
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `manual-${props.producto?.grupoProducto?.id}`;
      link.href = URL.createObjectURL(props.file);
      link.click();
    }
  }, [props.file, props.successDownload]);

  useEffect(() => {
    if (props.success && props.presupuesto && !props.presupuesto.tieneStock) {
      setOpen(true);
    }
  }, [props.success]);

  const handleConsultarDisponibilidad = () => {
    dispatch(
      requestConsultarDisponibilidad(id, myUser.clienteId, formik.values.cantidad)
    );
  };

  const formik = useFormik({
    initialValues: {
      productoId: producto.id,
      clienteId: myUser.clienteId,
      contactoId: myUser.contactoId,
      sellerId: myUser.sellerId,
      cantidad: cantMin,
      showLargo: showLargo,
      largo: showLargo ? largoMin : 0,
      showAncho: showAncho,
      ancho: showAncho ? largoMin : 0,
      precio: producto?.precio,
      peso: producto?.peso,
      monedaId: producto?.moneda?.id,
      userId: myUser.userId,
      tipoRol: myUser.tipoRol,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      /* clienteId: Yup.string()
            .when( 'clienteId' , {
                is: null,
                then: Yup.string().required('Seleccionar un cliente.'),
            }), */
      cantidad: Yup.number()
        .min(cantMin, `La unidad mínima de compra es ${cantMin}, por favor contáctese con su ejecutivo de cuenta.`)
        .required("La cantidad no puede ser cero."),
      largo: Yup.number().when("showLargo", {
        is: true,
        then: Yup.number()
          .min(largoMin, `El largo mínimo del producto es de ${largoMin}`)
          .max(largoMax, `El largo máximo del producto es de ${largoMax}`)
          .required("La dimensión Largo es requerida."),
      }),
      ancho: Yup.number().when("showAncho", {
        is: true,
        then: Yup.number()
          .min(largoMin, `El ancho mínimo del producto es de ${largoMin}`)
          .max(largoMax, `El ancho máximo del producto es de ${largoMax}`)
          .required("La dimensión Ancho es requerida."),
      }),
    }),
    onSubmit: (addProducto) => {
      props.requestPresupuestoAdd(addProducto);
    },
  });

  const history = useHistory();

  const handleDownload = async (grupoId) => {
    dispatch(requestDownloadFile(grupoId));
  };

  const handleClickSeguirComprando = () => {
    history.push(rutas.PRODUCTOS + "/" + grupoId);
  };

  return (
    <div
      style={{
        backgroundColor: colores.grisClaro,
        overflow: "hidden",
        clear: "both",
      }}
    >
      <Dolar></Dolar>

      <div className="d-flex justify-content-center">
        <hr style={{ width: "90%", color: "black" }}></hr>
      </div>
      {/*             <div className='d-flex justify-content-between flex-wrap align-items-center' style={{marginBottom:"0.600rem", paddingRight:"3rem"}}>
                <h1 className="fw-bolder" style={{marginLeft:"10%"}}>PRODUCTOS adas</h1>
            </div> */}

      <div
        className="container-fluid"
        style={{
          backgroundColor: "white",
          width: "100%",
          overflow: "hidden",
          clear: "both",
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-3 col-md-3 col-lg-3">
            <div
              className="d-flex align-items-start flex-column"
              style={{ overflowY: "scroll", maxHeight: 700 }}
            >
              {producto?.images?.$values?.map((imagen) => (
                <div
                  id={imagen.value}
                  className="card text-center"
                  style={{
                    width: "10rem",
                    marginBlock: "2rem",
                    marginInline: "auto",
                    border: "none",
                  }}
                >
                  <img
                    src={imagen}
                    className="card-img-top"
                    style={{ border: "1px solid black", borderRadius: "7px" }}
                    alt="Imagen del producto"
                    type="button"
                    onClick={() => setselectedImage(imagen)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="col-12 col-sm-4 col-md-4 col-lg-4">
            <div>
              {producto?.images?.$values[0] ? (
                <img
                  className="img-fluid"
                  style={{ margin: "2rem 0rem" }}
                  src={
                    selectedImage ? selectedImage : producto?.images?.$values[0]
                  }
                  alt=""
                />
              ) : (
                <img
                  className="img-fluid"
                  style={{ margin: "2rem 0rem" }}
                  src={ProductoSinImagen}
                  alt="Producto sin imagen"
                />
              )}
            </div>
          </div>

          <div
            className="col-12 col-sm-4 col-md-4 col-lg-4"
            style={{ margin: "2rem 0rem" }}
          >
            <div>
              <p style={{ fontSize: "1.8rem", fontWeight: "500" }}>
                {producto?.descripcion}
              </p>
              <p className="producto__text-code" style={{ fontSize: "0.9rem" }}>
                Código de producto:{" "}
                <span className="fw-bolder ">{producto?.id}</span>
              </p>
              <div className="separator"></div>
              <h1 className="fw-bolder" style={{ fontSize: "1.9rem" }}>
                <strong>
                  {producto?.moneda?.simbolo} {producto?.precio}
                </strong>
              </h1>
              <p>
                Peso <span className="fw-bolder">{producto?.peso} kg</span>
              </p>
              <p>
                Categoría{" "}
                <span className="fw-bolder">{producto?.grupoDescripcion}</span>
              </p>
              {producto?.grupoDescripcion === "Herramientas" ? (
                <p>
                  <span className="fw-bolder" style={{ fontSize: "0.8125rem" }}>
                    (La disponibilidad de los productos solicitados en el
                    presupuesto están sujetos a verificación de stock)
                  </span>
                </p>
              ) : (
                ""
              )}
              <div>
                <img
                  src={CalendarLogo}
                  alt="Calendar.svg"
                  className="producto__icon-stock"
                />
                <p className="producto__text-stock">
                  En caso de no haber stock inmediato en el punto de Pick Up, el
                  producto estará disponible en 4 días hábiles
                </p>
              </div>
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="d-flex" style={{ margin: "1rem 0rem" }}>
                    {showLargo ? (
                      <div
                        style={{
                          marginRight: "1rem",
                          width: "5rem",
                          Height: "2.125rem",
                        }}
                      >
                        <label for="largo" className="form-label fw-bolder">
                          LARGO
                        </label>
                        <input
                          type="number"
                          step=".001"
                          class="form-control"
                          id="largo"
                          Value={largoMin}
                          onChange={formik.handleChange}
                          error={formik.errors.largo}
                          style={{ border: "1px solid black", width: "12vh" }}
                        />
                      </div>
                    ) : null}
                    {showAncho ? (
                      <div
                        style={{
                          marginRight: "1rem",
                          width: "5rem",
                          Height: "2.125rem",
                        }}
                      >
                        <label for="ancho" className="form-label fw-bolder">
                          ANCHO
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="ancho"
                          Value={largoMin}
                          onChange={formik.handleChange}
                          error={formik.errors.ancho}
                          style={{ border: "1px solid black", width: "12vh" }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {formik.errors.largo && formik.touched.largo ? (
                    <div className="text-danger">{formik.errors.largo}</div>
                  ) : null}
                  {formik.errors.ancho && formik.touched.ancho ? (
                    <div className="text-danger">{formik.errors.ancho}</div>
                  ) : null}

                  <div className="separator"></div>

                  <div className="producto__btn-qty">
                    <label for="ancho" className="form-label fw-bolder">
                      CANTIDAD
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="cantidad"
                      Value={cantMin}
                      onChange={formik.handleChange}
                      error={formik.errors.cantidad}
                      style={{ border: "1px solid black", width: "12vh" }}
                    />
                  </div>

                  {isAllowed(14) && isAllowed(16) ? (
                    props.productos === true ? (
                      <div>
                        <Button
                          className="my-3"
                          style={{
                            backgroundColor: "#cc615a",
                            color: "#fff",
                            "margin-top": "15px !important",
                          }}
                          variant="contained"
                          disabled={true}
                          type="submit"
                        >
                          CARGANDO...
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                            className="my-3 producto__btn-add"
                            style={{ "margin-top": "15px !important" }}
                            variant="contained"
                            type="submit"
                          >
                            AGREGAR A CARRITO
                          </Button>
                        {/* {props.producto?.tieneStock ? (
                          <Button
                            className="my-3 producto__btn-add"
                            style={{ "margin-top": "15px !important" }}
                            variant="contained"
                            type="submit"
                          >
                            AGREGAR A CARRITO
                          </Button>
                        ) : (
                          <Button
                            className="my-3 producto__btn-add"
                            style={{ "margin-top": "15px !important" }}
                            variant="contained"
                            onClick={handleConsultarDisponibilidad}
                          >
                            CONSULTAR DISPONIBILIDAD
                          </Button>
                        )} */}
                      </div>
                    )
                  ) : null}
                  {formik.errors.cantidad && formik.touched.cantidad ? (
                    <div className="text-danger">{formik.errors.cantidad}</div>
                  ) : (
                    ""
                  )}
                  {isAllowed(14) && isAllowed(16) ? (
                    <div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Link
                          to={rutas.CARRITO}
                          style={{ color: colores.rojo }}
                        >
                          <b>Ver carrito</b>
                        </Link>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <button
                          type="button"
                          class="btn btn-outline-success my-3"
                          style={{ textTransform: "uppercase" }}
                          onClick={(e) => handleClickSeguirComprando()}
                        >
                          Seguir Comprando
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {props.error ? (
                    <div
                      className="alert alert-danger text-center"
                      style={{ width: "17.5rem" }}
                      role="alert"
                    >
                      {props?.error}
                    </div>
                  ) : (
                    ""
                  )}
                  {props.success &&
                  props.presupuesto &&
                  props.presupuesto.tieneStock ? (
                    <div
                      className="alert alert-success text-center"
                      style={{ width: "17.5rem" }}
                      role="alert"
                    >
                      <p style={{ margin: 0, padding: 0 }}>
                        Producto agregado al carrito <CheckCircleOutlineIcon />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
              {isAllowed(6) && productsSuggestion.length > 0 && (
                <div>
                  <div
                    className="d-flex justify-content-start"
                    style={{ margin: "1rem 0rem", marginTop: "2rem" }}
                  >
                    <p
                      className="fw-bolder"
                      style={{
                        marginRight: "0.700rem",
                        backgroundColor: "#FFFF00",
                        fontSize: "1rem",
                        padding: "5px",
                      }}
                    >
                      PRODUCTOS <b>SUGERIDOS</b>
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-start overflow-auto"
                    style={{
                      margin: "0.5rem 0rem",
                      width: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    {productsSuggestion?.map((sugerido) => (
                      <CardProduct key={sugerido.id} data={sugerido} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <hr
            style={{
              width: "80%",
              border: "1px solid black",
              color: "black",
              margin: "2rem 0rem",
            }}
          ></hr>
        </div>

        <div className="container" style={{ width: "60%" }}>
          <div className="row">
            <div className="col-12">
              <h5 className="fw-bolder">DESCRIPCIÓN</h5>
              <p className="fw-bolder">{producto?.grupoDetalle}</p>
            </div>
            <div className="col-12">
              <Button
                onClick={() =>
                  handleDownload(props.producto?.grupoProducto?.id)
                }
                style={{ textDecoration: "none", color: "red" }}
              >
                DESCARGAR MANUAL DE USUARIO
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <hr
            style={{
              width: "80%",
              border: "1px solid black",
              color: "black",
              margin: "4rem 0rem",
            }}
          ></hr>
        </div>
      </div>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="consultar-disponibilidad-title"
        aria-describedby="consultar-disponibilidad-description"
      >
        <DialogTitle id="consultar-disponibilidad-title">
          {"Consultar disponibilidad"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="consultar-disponibilidad-description">
            Se envió un email a los vendedores correspondientes para consultar
            la disponibilidad.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default connect(
  (state) => ({
    producto: state.producto.producto,
    loading: state.presupuestos.loading,
    error: state.presupuestos.error,
    success: state.presupuestos.success,
    presupuesto: state.presupuestos.presupuesto,
    file: state.producto.file,
    successDownload: state.producto.successDownload,
    enviado: state.producto.enviado,
  }),
  (dispatch) => ({
    requestProducto: (id, clienteId) =>
      dispatch(requestProducto(id, clienteId)),
    requestPresupuestoAdd: (addProducto) =>
      dispatch(requestPresupuestoAdd(addProducto)),
    addProductFalse: (id) => dispatch(addProductFalse()),
    requestDownloadFile: (grupoId) => dispatch(requestDownloadFile(grupoId)),
    requestConsultarDisponibilidad: (id, clienteId) =>
      dispatch(requestConsultarDisponibilidad(id, clienteId)),
  })
)(Producto);
