import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Params/`

export default class params {
    
    static async findDniTyps() {
        const respuesta = await axios.get(`${ENDPOINT}identificationsTypes`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }
    static async findRegions() {
        const respuesta = await axios.get(`${ENDPOINT}regions`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }
    static async findGruposProductos() {
        const respuesta = await axios.get(`${ENDPOINT}subcategorias`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values;
    }
    static async fetchDolar() {
        const respuesta = await axios.get(`${ENDPOINT}dolar`)
        .then(response => {
            return response
        })  
        return respuesta.data.data;
    }

    static async kilosRequest(params) {
    
        const data = {
            clienteId: params.ClienteId,
            firstDate: params.DateFrom.format('YYYY-MM-DD'),
            lastdDate: params.DateTo.format('YYYY-MM-DD'),
            productGrouptId: params.Category
        };
        const respuesta = await axios.post(`${ENDPOINT}kilosDashboard`, {
            ...data
        })
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }
}
