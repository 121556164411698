import { connect } from "react-redux";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Card, CardHeader } from "@mui/material";

interface CardProps {
    title: string,
    data: string
}

const CardSaldo = (props: CardProps) => {

    return (
        <div style={{ margin: "2vw" }}>
            <Card
                style={{
                    width: 180,
                    maxWidth: 300,
                    height: 180,
                    backgroundColor: "#f5f0f0"
                }}
                ><CardHeader
                    style={{height: "90px", padding: "1vw"}}
                    titleTypographyProps={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    style: {
                        marginBottom: "1vh"
                    },
                    }}
                    title={props.title}
                />
                <CardContent style={{ height: "140px", width: "220px", padding: "0vw 1vw 1vw 1vw" }}>
                    <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                        fontSize: "1rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "6",
                        WebkitBoxOrient: "vertical",
                    }}
                    >
                    R$ {props.data}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};
export default connect()(CardSaldo);
