export const Types= {
    CLIENTE_LOADING: 'CLIENTE_LOADING',
    CLIENTECONTACT_FETCH_REQUESTED: 'CLIENTECONTACT_FETCH_REQUESTED',
    CLIENTESCONTACT_FETCH_SUCCEEDED: 'CLIENTESCONTACT_FETCH_SUCCEEDED',
    CLIENTESCONTACT_FETCH_ERROR: 'CLIENTESCONTACT_FETCH_ERROR',
    CLIENTES_FETCH_REQUESTED: 'CLIENTES_FETCH_REQUESTED',
    CLIENTES_FETCH_SUCCEEDED: 'CLIENTES_FETCH_SUCCEEDED',
    CLIENTES_FETCH_ERROR: 'CLIENTES_FETCH_ERROR',
    CLIENTES_BY_SELLER_FETCH_REQUESTED: 'CLIENTES_BY_SELLER_FETCH_REQUESTED',
    CLIENTES_BY_SELLER_FETCH_SUCCEEDED: 'CLIENTES_BY_SELLER_FETCH_SUCCEEDED',
    CLIENTES_BY_SELLER_FETCH_ERROR: 'CLIENTES_BY_SELLER_FETCH_ERROR',
    CLIENTES_FETCH_ALL_REQUESTED: 'CLIENTES_FETCH_ALL_REQUESTED',
    CLIENTES_FETCH_ALL_SUCCEEDED: 'CLIENTES_FETCH_ALL_SUCCEEDED',
    CLIENTES_FETCH_ALL_ERROR: 'CLIENTES_FETCH_ALL_ERROR',
    CLIENTES_FIND_REQUESTED: 'CLIENTES_FIND_REQUESTED',
    CLIENTES_FIND_SUCCEEDED: 'CLIENTES_FIND_SUCCEEDED',
    CLIENTES_FIND_ERROR: 'CLIENTES_FIND_ERROR',
    CLIENTES_SEARCH_REQUESTED: 'CLIENTES_SEARCH_REQUESTED',
    CLIENTES_SEARCH_SUCCEEDED: 'CLIENTES_SEARCH_SUCCEEDED',
    CLIENTES_SEARCH_ERROR: 'CLIENTES_SEARCH_ERROR',
    CLIENTES_FIND_REQUESTED_BY_DOC: 'CLIENTES_FIND_REQUESTED_BY_DOC',
    CLIENTES_FIND_SUCCEEDED_BY_DOC: 'CLIENTES_FIND_SUCCEEDED_BY_DOC',
    CLIENTES_FIND_ERROR_BY_DOC: 'CLIENTES_FIND_ERROR_BY_DOC',
    
    CLIENTES_EDIT_ADDRESS_REQUESTED: 'CLIENTES_EDIT_ADDRESS_REQUESTED',
    CLIENTES_EDIT_ADDRESS_SUCCEEDED: 'CLIENTES_EDIT_ADDRESS_SUCCEEDED',
    CLIENTES_EDIT_ADDRESS_ERROR: 'CLIENTES_EDIT_ADDRESS_ERROR'
};

export const startLoading = () => ({type: Types.CLIENTE_LOADING});

export const requestClienteContact = (formData) => ({type: Types.CLIENTECONTACT_FETCH_REQUESTED, formData});
export const receiveClienteContact = success => ({type: Types.CLIENTESCONTACT_FETCH_SUCCEEDED, success})
export const receiveClienteContactError = err => ({type: Types.CLIENTESCONTACT_FETCH_ERROR, err})

export const requestClientes = () => ({type: Types.CLIENTES_FETCH_REQUESTED});
export const receiveClientes = clientes => ({type: Types.CLIENTES_FETCH_SUCCEEDED, clientes})
export const receiveClientesError = err => ({type: Types.CLIENTES_FETCH_ERROR, err})

export const requestClientesBySeller = (id) => ({type: Types.CLIENTES_BY_SELLER_FETCH_REQUESTED, id});
export const receiveClientesBySeller = clientes => ({type: Types.CLIENTES_BY_SELLER_FETCH_SUCCEEDED, clientes})
export const receiveClientesBySellerError = err => ({type: Types.CLIENTES_BY_SELLER_FETCH_ERROR, err})

export const requestAllClientes = () => ({type: Types.CLIENTES_FETCH_ALL_REQUESTED});
export const receiveAllClientes = clientes => ({type: Types.CLIENTES_FETCH_ALL_SUCCEEDED, clientes})
export const receiveAllClientesError = err => ({type: Types.CLIENTES_FETCH_ALL_ERROR, err})

export const requestCliente = (id, contactoId) => ({type: Types.CLIENTES_FIND_REQUESTED, id, contactoId});
export const receiveClienteSucceeded = cliente => ({type: Types.CLIENTES_FIND_SUCCEEDED, cliente})
export const receiveClienteError = err => ({type: Types.CLIENTES_FIND_ERROR, err})

export const requestClientesSearch = (search) => ({type: Types.CLIENTES_SEARCH_REQUESTED, search});
export const receiveClientesSearchSucceeded = clientes => ({type: Types.CLIENTES_SEARCH_SUCCEEDED, clientes})
export const receiveClientesSearchError = err => ({type: Types.CLIENTES_SEARCH_ERROR, err})

export const requestEditAddress = (updateAddressRequest) => ({type: Types.CLIENTES_EDIT_ADDRESS_REQUESTED, updateAddressRequest});
export const responseEditAddressSucceeded = success => ({type: Types.CLIENTES_EDIT_ADDRESS_SUCCEEDED, success})
export const responseEditAddressError = err => ({type: Types.CLIENTES_EDIT_ADDRESS_ERROR, err})

export const requestClienteByDoc = (nroDoc, tipoDoc) => ({type: Types.CLIENTES_FIND_REQUESTED_BY_DOC, nroDoc, tipoDoc});
export const receiveClienteSucceededByDoc = cliente => ({type: Types.CLIENTES_FIND_SUCCEEDED_BY_DOC, cliente})
export const receiveClienteErrorByDoc = err => ({type: Types.CLIENTES_FIND_ERROR_BY_DOC, err})