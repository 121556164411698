export const Types= {
    TRACKING_LOADING: 'TRACKING_LOADING',
    TRACKING_GET_REQUEST: 'TRACKING_GET_REQUEST',
    TRACKING_GET_SUCCEEDED: 'TRACKING_GET_SUCCEEDED',
    TRACKING_GET_ERROR: 'TRACKING_GET_ERROR'
};

export const startLoading = () => ({type: Types.TRACKING_LOADING});

export const getByPedidoId = (pedidoId) => ({type: Types.TRACKING_GET_REQUEST, pedidoId});
export const getByPedidoIdSucceeded = (tracking) => ({type: Types.TRACKING_GET_SUCCEEDED, tracking})
export const getByPedidoIdError = (err) => ({type: Types.TRACKING_GET_ERROR, err})