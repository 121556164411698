import { Types } from '../actions/auth';

export default function authReducer(state = {loading: false, success: false, successPassword: false, successPasswordReset: false, errorPasswordReset: false, successPasswordEmail: false, errorPasswordEmail: false}, action) {
    switch (action.type) {
        case Types.AUTH_OATH_REQUEST:
            return {...state, loading: true }
        case Types.AUTH_OATH_SUCCEEDED:
            return {...state, success: true };
        case Types.AUTH_OATH_ERROR:
            return {...state, error: action.error, loading: false};
        case Types.AUTH_FLAG:
            return {...state, success: false}
        case Types.AUTH_CHANGEPASSWORD_REQUEST:
            return {...state, loading: true}
        case Types.AUTH_CHANGEPASSWORD_REQUEST_SUCCEEDED:
            return {...state, loading: false, successPassword: true}
        case Types.AUTH_CHANGEPASSWORD_REQUEST_ERROR:
            return {...state, error: action.error, loading: false, successPassword: false};
        case Types.AUTH_RESET_PASSWORDEMAIL_REQUESTED:
            return {...state, loading: true }
        case Types.AUTH_RESET_PASSWORDEMAIL_SUCCEEDED:
            return {...state, data: action.data, successPasswordEmail: true, errorPasswordEmail: false, loading: false};
        case Types.AUTH_RESET_PASSWORDEMAIL_ERROR:
            return {...state, loading: false, error: action.error, successPasswordEmail: false, errorPasswordEmail: true};
        case Types.AUTH_RESET_PASSWORD_REQUESTED:
            return {...state, loading: true }
        case Types.AUTH_RESET_PASSWORD_SUCCEEDED:
            return {...state, data: action.data, successPasswordReset: true, errorPasswordReset: false};
        case Types.AUTH_RESET_PASSWORD_ERROR:
            return {...state, errorPasswordReset: true, error: action.error, loading: false, successPasswordReset: false};
        default:
            return state;
    }
}