const throwRefreshMyUserError = () => {
    throw new Error('HeimdallError: The refresh MyUser should not be used on web browsers.');
};

export default class MyUserService {

    static getMyUser() {
        return JSON.parse(window.localStorage.getItem('LocalSession'));
    }
    static getMyGroup() {
        return JSON.parse(window.localStorage.getItem('grupoId'));
    }

    /**
     * Store the refresh MyUser
     */
    static setRefreshMyUser() {
        throwRefreshMyUserError();
    }
}