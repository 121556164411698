import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/tracking';
import tracking from '../services/tracking';

function* getTracking({pedidoId}) {
    yield put(actions.startLoading());
    try {
        const response = yield call(tracking.getTracking, pedidoId);
        if(response){
            yield put(actions.getByPedidoIdSucceeded(response));
        }
    } catch (err) {
        yield put(actions.getByPedidoIdError(err));
        console.log(err)
    }
}

function*  watchGetTrackingByPedidoId() {
    yield takeEvery(actions.Types.TRACKING_GET_REQUEST, getTracking);
}

const trackingSagas = [
    fork(watchGetTrackingByPedidoId)
]

export default trackingSagas;