import { connect } from "react-redux";
import colores from "../../../shared/constants/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from '@mui/icons-material/Close';

interface ITrackingDetalleProps {
    trackings: any;
    handleClose?: () => void;
}

const TrackingDetalle = (props: ITrackingDetalleProps) => {
    return (
        <div>
            <div className="container" style={{ width: "100%" }}>
                <Accordion expanded={true} style={{ borderRadius: "15px" }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                            backgroundColor: colores.grisClaro,
                            paddingTop: "0.3rem",
                            paddingBottom: "0.8rem",
                            margin: 0,
                            borderRadius: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                        }}
                    >
                        <h4 style={{ fontWeight: "lighter" }}>SEGUIMIENTO DE PEDIDO</h4>
                        <div style={{ marginLeft: "auto" }}> {/* Este contenedor empuja el icono a la derecha */}
                            <CloseIcon
                                onClick={() => props.handleClose && props.handleClose()}
                                style={{ cursor: "pointer", fontSize: "20px" }}
                            />
                        </div>
                    </AccordionSummary>
                    
                    <AccordionDetails>
                    {props.trackings?.$values.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "white",
                                paddingTop: "2.20rem",
                                paddingBottom: "1.20rem",
                            }}>
                        <div
                            className="row row-cols-1 row-cols-sm-3 row-cols-md-5"
                            style={{ width: "90%", marginLeft: "-40px" }}>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "10%" }}>
                                <p style={{ fontSize: "15px" }} className="text-center"> Viaje</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                <p style={{ fontSize: "15px" }}>Estado</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                <p style={{ fontSize: "15px" }}>Fecha y Hora</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                <p style={{ fontSize: "15px" }}>Transporte</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "10%" }}>
                                <p style={{ fontSize: "15px" }}>Placa</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "15%" }}>
                                <p style={{ fontSize: "15px" }}>Conductor</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "10%" }} >
                                <p style={{ fontSize: "15px" }}>Dni</p>
                            </div>
                            <div className="col" style={{ fontWeight: "bold", maxWidth: "5%" }}>
                                <p style={{ fontSize: "15px" }}>Teléfono</p>
                            </div>
                        </div>
                        {props.trackings?.$values?.map((tracking: any) => (
                            <div key={tracking.$id} className="row row-cols-1 row-cols-sm-3 row-cols-md-5" style={{ width: "90%", marginLeft: "-40px" }}>
                                <div className="col" style={{ maxWidth: "10%" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {tracking.estadoViaje === "Reprogramado" ? (
                                            <ReplayCircleFilledIcon
                                            style={{
                                                fill: "black",
                                                width: "2.2vh",
                                                height: "2.2vh",
                                                marginTop: "-15px",
                                            }}
                                            />
                                        ) : tracking.estadoViaje === "Despachado" ? (
                                            <LocalShippingIcon
                                            style={{
                                                fill: "#1c4f97",
                                                width: "2.2vh",
                                                height: "2.2vh",
                                                marginTop: "-15px",
                                            }}
                                            />
                                        ) : (
                                            <CheckCircleIcon
                                            style={{
                                                fill: "green",
                                                width: "2.2vh",
                                                height: "2.2vh",
                                                marginTop: "-15px",
                                            }}
                                            />
                                        )}
                                        <p style={{ fontSize: "15px", marginLeft: "5px" }} className="text-center">
                                            {tracking.idViaje}
                                        </p>
                                    </div>
                                </div>
                                <div className="col" style={{ maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.estadoViaje}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.fechaHora}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.razonSocial}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "10%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.placaCamion}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "15%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.nombreConductor}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "10%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.nroDocumento}
                                    </p>
                                </div>
                                <div className="col" style={{ maxWidth: "5%" }}>
                                    <p style={{ fontSize: "15px" }}>
                                    {tracking.telefono}
                                    </p>
                                </div>
                            </div>
                        ))}
                        </div>) : "NO HAY SEGUIMIENTO"}
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default connect()(TrackingDetalle);
