import { connect, useDispatch } from "react-redux";
import TrackingDetalle from "../../../common/components/tracking/tracking_detalle";
import { getByPedidoId } from "../../../actions/tracking";
import { useEffect } from "react";

interface ITrackingPedidoProps {
    handleClose: () => void;
    pedidoId: string;
    tracking?: any,        
    error?: any,    
    loading?: any
}

const TrackingPedido = (props: ITrackingPedidoProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getByPedidoId(props.pedidoId));
    }, []);
    
    useEffect(() => {
        console.log(props.tracking);
    }, [props.tracking]);
    
    return (
        <div>
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: "1",
                    backgroundColor: "black",
                    filter: "alpha(opacity=75)",
                    opacity: 0.75
                }}> 
            </div>
            <div
                className="container"
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "25%",
                    transform: "translate(-50%, -25%)",
                    bottom: "0",
                    zIndex: "2",
                    overflowY: "auto",
                    overflowX: "hidden"
                }}>
                <TrackingDetalle trackings={props.tracking?.data} handleClose={props.handleClose}></TrackingDetalle>
            </div>
        </div>
    );
}

export default connect(
    (state: any) => ({
        tracking: state.tracking.tracking,        
        error: state.tracking.error,    
        loading: state.tracking.loading
    }),
    (dispatch) => ({
        getByPedidoId: (pedidoId: string) => dispatch(getByPedidoId(pedidoId))
    })
)(TrackingPedido);  