import {call, put, takeEvery, fork} from 'redux-saga/effects'
import * as actions from '../actions/cliente';

import clienteService from '../services/cliente';

function* ClientContact({formData}) {
    yield put(actions.startLoading());
    try {
        const cliente = yield call(clienteService.contact, formData);
        yield put(actions.receiveClienteContact(cliente));
    } catch (err) {
        yield put(actions.receiveClienteContactError(err));
        console.log(err)
    }
}

function* fetchClients() {
    yield put(actions.startLoading());
    try {
        const clientes = yield call(clienteService.fetch);
        yield put(actions.receiveClientes(clientes));
    } catch (err) {
        yield put(actions.receiveClientesError(err));
        console.log(err)
    }
}

function* fetchClientsBySeller({id}) {
    yield put(actions.startLoading());
    try {
        const clientes = yield call(clienteService.fetchBySeller, id);
        yield put(actions.receiveClientesBySeller(clientes));
    } catch (err) {
        yield put(actions.receiveClientesBySellerError(err));
        console.log(err)
    }
}

function* fetchAllClients() {
    yield put(actions.startLoading());
    try {
        const clientes = yield call(clienteService.fetchAll);
        yield put(actions.receiveClientes(clientes));
    } catch (err) {
        yield put(actions.receiveClientesError(err));
        console.log(err)
    }
}

function* findClients({id, contactoId}) {
    yield put(actions.startLoading());
    try {
        const cliente = yield call(clienteService.find, id, contactoId);
        yield put(actions.receiveClienteSucceeded(cliente));
    } catch (err) {
        yield put(actions.receiveClienteError(err));
        console.log(err)
    }
}

function* getClienteByNroDoc({nroDoc, tipoDoc}) {
    yield put(actions.startLoading());
    try {
        const cliente = yield call(clienteService.getClienteByNroDoc, nroDoc, tipoDoc);
        yield put(actions.receiveClienteSucceededByDoc(cliente));
    } catch (err) {
        yield put(actions.receiveClienteErrorByDoc(err));
        console.log(err)
    }
}

function* searchClients({search}) {
    yield put(actions.startLoading());
    try {
        const clientes = yield call(clienteService.search, search);
        yield put(actions.receiveClientes(clientes));
    } catch (err) {
        yield put(actions.receiveClienteError(err));
        console.log(err)
    }
}

function* updateAddress({updateAddressRequest}) {
    yield put(actions.startLoading());
    try {
        const success = yield call(clienteService.updateAddress, updateAddressRequest);
        yield put(actions.responseEditAddressSucceeded(success));
    } catch (err) {
        yield put(actions.responseEditAddressError(err));
        console.log(err)
    }
}

function*  watchGetClientsContactRequest() {
    yield takeEvery(actions.Types.CLIENTECONTACT_FETCH_REQUESTED, ClientContact);
}
function*  watchGetClientsRequest() {
    yield takeEvery(actions.Types.CLIENTES_FETCH_REQUESTED, fetchClients);
}
function*  watchGetClientsBySellerRequest() {
    yield takeEvery(actions.Types.CLIENTES_BY_SELLER_FETCH_REQUESTED, fetchClientsBySeller);
}
function*  watchGetAllClientsRequest() {
    yield takeEvery(actions.Types.CLIENTES_FETCH_ALL_REQUESTED, fetchAllClients);
}
function*  watchFindClientsRequest() {
    yield takeEvery(actions.Types.CLIENTES_FIND_REQUESTED, findClients);
}
function*  watchFindClientsRequestByDoc() {
    yield takeEvery(actions.Types.CLIENTES_FIND_REQUESTED_BY_DOC, getClienteByNroDoc);
}
function*  watchSearchClientsRequest() {
    yield takeEvery(actions.Types.CLIENTES_SEARCH_REQUESTED, searchClients);
}
function*  watchEditAddressRequest() {
    yield takeEvery(actions.Types.CLIENTES_EDIT_ADDRESS_REQUESTED, updateAddress);
}

const clienteSagas = [
    fork(watchGetClientsRequest),
    fork(watchFindClientsRequest),
    fork(watchFindClientsRequestByDoc),
    fork(watchSearchClientsRequest),
    fork(watchGetClientsContactRequest),
    fork(watchGetAllClientsRequest),
    fork(watchGetClientsBySellerRequest),
    fork(watchEditAddressRequest)
]
export default clienteSagas;