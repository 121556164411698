import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Vendedor/`

export default class ParamsService {
    static async fetch() {
        const respuesta = await axios.get(`${ENDPOINT}all`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }
    static async find(id) {
        const respuesta = await axios.get(`${ENDPOINT}${id}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async search(search) {
        const respuesta = await axios.get(`${ENDPOINT}search?name=${search}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async save(vendedor) {
        console.log(vendedor)
        const respuesta = await axios.post(`${ENDPOINT}`,vendedor)
        .then(response => {
            return response
        })  
        return respuesta.data
    }
    static async edit(vendedor) {
        const respuesta = await axios.put(`${ENDPOINT}${vendedor.id}`,{vendedor})
        .then(response => {
            return response
        })
        return respuesta.data
    }
}
