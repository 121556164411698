export const Types= {
    AUTH_OATH_REQUEST: 'AUTH_OATH_REQUEST',
    AUTH_OATH_SUCCEEDED: 'AUTH_OATH_SUCCEEDED',
    AUTH_FLAG: 'AUTH_FLAG',
    AUTH_CHANGEPASSWORD_REQUEST: 'AUTH_CHANGEPASSWORD_REQUEST',
    AUTH_LOADING: 'AUTH_LOADING',
    AUTH_CHANGEPASSWORD_REQUEST_SUCCEEDED: 'AUTH_CHANGEPASSWORD_REQUEST_SUCCEEDED',
    AUTH_CHANGEPASSWORD_REQUEST_ERROR: 'AUTH_CHANGEPASSWORD_REQUEST_ERROR',
    AUTH_RESET_PASSWORD_REQUESTED: 'AUTH_RESET_PASSWORD_REQUESTED',
    AUTH_RESET_PASSWORD_SUCCEEDED: 'AUTH_RESET_PASSWORD_SUCCEEDED',
    AUTH_RESET_PASSWORD_ERROR: 'AUTH_RESET_PASSWORD_ERROR',
    AUTH_OATH_ERROR: 'AUTH_OATH_ERROR',
    AUTH_RESET_PASSWORDEMAIL_REQUESTED: 'AUTH_RESET_PASSWORDEMAIL_REQUESTED',
    AUTH_RESET_PASSWORDEMAIL_SUCCEEDED: 'AUTH_RESET_PASSWORDEMAIL_SUCCEEDED',
    AUTH_RESET_PASSWORDEMAIL_ERROR: 'AUTH_RESET_PASSWORDEMAIL_ERROR'
};

export const startLoading = () => ({type: Types.AUTH_LOADING});
export const login = (loginData) => ({type: Types.AUTH_OATH_REQUEST, loginData});
export const flag = () => ({type: Types.AUTH_FLAG});
export const changePassword = (data) => ({type: Types.AUTH_CHANGEPASSWORD_REQUEST, data});
export const changePasswordSucceeded = (successPassword) => ({type: Types.AUTH_CHANGEPASSWORD_REQUEST_SUCCEEDED, successPassword});
export const changePasswordError = (error) => ({type: Types.AUTH_CHANGEPASSWORD_REQUEST_ERROR, error});
export const loginSucceeded = ()  => ({type: Types.AUTH_OATH_SUCCEEDED })
export const loginError = (error) => ({type: Types.AUTH_OATH_ERROR, error})
export const requestResetPassword = (data) => ({type: Types.AUTH_RESET_PASSWORD_REQUESTED, data});
export const receiveResetPasswordSucceeded = success => ({type: Types.AUTH_RESET_PASSWORD_SUCCEEDED, success})
export const receiveResetPasswordError = (error) => ({type: Types.AUTH_RESET_PASSWORD_ERROR, error})
export const changePasswordEmail = (data) => ({type: Types.AUTH_RESET_PASSWORDEMAIL_REQUESTED, data});
export const changePasswordEmailSucceeded = () => ({type: Types.AUTH_RESET_PASSWORDEMAIL_SUCCEEDED});
export const changePasswordEmailError = (error) => ({type: Types.AUTH_RESET_PASSWORDEMAIL_ERROR, error});
