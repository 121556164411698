import { all } from "redux-saga/effects";

import authSagas from "./auth";
import clienteSagas from "./clientes";
import paramsSagas from "./params";
import presupuestoSagas from "./presupuestos";
import productsSagas from "./productos";
import usersSagas from "./users";
import vendedorSagas from "./vendedor";
import suggestedProductSagas from "./suggestedProduct";
import reclamosSagas from "./reclamos";
import configuracionSagas from "./configuracion";
import acopiosSagas from "./acopios";
import novedadesSagas from "./novedades";
import cuentacorrienteSagas from "./cuentacorriente";
import consulsteelSagas from "./consulSteel";
import trackingSagas from "./tracking";

// Si uno se escribe dos veces, hace doble llamado a la API
export default function* root() {
  yield all([
    ...authSagas,
    ...clienteSagas,
    ...paramsSagas,
    ...presupuestoSagas,
    ...productsSagas,
    ...usersSagas,
    ...suggestedProductSagas,
    ...vendedorSagas,
    ...reclamosSagas,
    ...configuracionSagas,
    ...acopiosSagas,
    ...novedadesSagas,
    ...cuentacorrienteSagas,
    ...consulsteelSagas,
    ...trackingSagas
  ]);
}
