import colores from "../../../shared/constants/styles";
import IFilterComposicion from "./interface/IFilterComposicion";
import { connect } from "react-redux";
import { useEffect } from "react";
import { FormControl, TextField } from "@mui/material";

interface IFilterProps {
    filter:IFilterComposicion;
    onSelectedFilter: (filter: IFilterComposicion)=> void;
    getFilterComposicion: ()=> void;
}

const Filter = (props: IFilterProps) => {
    const localStorage = JSON.parse(window.localStorage.getItem("LocalSession") || '{}');

    const handleDocumentoToChange = (event: any) => {
        props.onSelectedFilter({
            ...props.filter,
            documento: event.target.value
        });
    };

    const search = (event: any) => {
        event.preventDefault();
        props.getFilterComposicion();
    }

    const handleClean= () => {
        props.onSelectedFilter({
            documento: "",
            clienteId: localStorage.clienteId.trim(),
            page: 0,
            rowPerPage:0
        })
    }

    useEffect(() => {
        
    }, [])
    
    return (
        <div className='container-fluid mx-0 px-0 w-100' style={{margin:"2rem"}}>
            <div className="container-fluid" style={{backgroundColor: "white", width: "100%", overflow:"hidden", clear:"both", marginBottom: "1.85rem"}}>
                <form className="row row-cols-1 row-cols-sm-8 row-cols-md-8" onSubmit={search} style={{margin:"1vw"}}>
                    <div>
                        <div className="row">
                            <div className="col-2">
                                <FormControl fullWidth>
                                    <TextField
                                        id="documento"
                                        value={props.filter.documento}
                                        name="documento"
                                        onChange={handleDocumentoToChange}
                                        label="Documento"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>      
                <div style={{margin:"1vw"}}>
                    <button type="submit" style={{background: colores.rojo, color: 'white'}} className="btn">Buscar</button>
                    <button style={{background: colores.white, color: 'red', borderColor: 'red', marginLeft: '2vh'}} className="btn" onClick={handleClean}>Limpiar</button>
                </div>
                </form>                
            </div>
        </div>
    );
};
export default connect(
    (state:any) => ({
        loading: state.cuentacorriente.loading,
    })
)(Filter);
